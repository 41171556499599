var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-5"},[_c('v-row',{staticClass:"d-flex justify-center ma-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"xs":"12","sm":"10","md":"8"}},[_c('v-autocomplete',{attrs:{"items":_vm.currentIdema,"dense":"","label":_vm.$t('aemet.weatherStation')},model:{value:(_vm.idemaCode),callback:function ($$v) {_vm.idemaCode=$$v},expression:"idemaCode"}})],1)],1),_c('v-row',{staticClass:"d-flex justify-center ma-0"},[_c('v-col',{attrs:{"xs":"12","sm":"10","md":"8"}},[_c('v-card',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{attrs:{"colSpan":"2"}},[_vm._v(" "+_vm._s(this.apiData.ubi ? this.idemaCode : "")+" ")])])]),_c('tbody',[_c('tr',[_c('th',{staticClass:"d-flex align-center justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t("aemet.lastActualization")))]),(this.apiData.fint)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,false,4218361139)},[_c('span',[_vm._v(_vm._s(_vm.$t("aemet.lastActualizationInfo")))])]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(this.apiData.fint ? ("" + (this.apiData.fint.split( "T" )[1])) : "-")+" ")])]),_c('tr',[_c('th',{staticClass:"d-flex align-center justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t("aemet.altitude")))]),(this.apiData.fint)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,false,4218361139)},[_c('span',[_vm._v(_vm._s(_vm.$t("aemet.altitudeInfo")))])]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(this.apiData.alt || this.apiData.alt === 0 ? ((this.apiData.alt) + " m") : "-")+" ")])]),_c('tr',[_c('th',{staticClass:"d-flex align-center justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t("aemet.lastHourRainfall")))]),(this.apiData.fint)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,false,4218361139)},[_c('span',[_vm._v(_vm._s(_vm.$t("aemet.lastHourRainfallInfo")))])]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(this.apiData.prec || this.apiData.prec === 0 ? ((this.apiData.prec) + " mm") : "-")+" ")])]),_c('tr',[_c('th',{staticClass:"d-flex align-center justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t("aemet.windSpeed")))]),(this.apiData.fint)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,false,4218361139)},[_c('span',[_vm._v(_vm._s(_vm.$t("aemet.windSpeedInfo")))])]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(this.apiData.vv || this.apiData.vv === 0 ? ((this.apiData.vv) + " m/s") : "-")+" ")])]),_c('tr',[_c('th',{staticClass:"d-flex align-center justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t("aemet.windDirection")))]),(this.apiData.fint)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,false,4218361139)},[_c('span',[_vm._v(_vm._s(_vm.$t("aemet.windDirectionInfo")))])]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(this.apiData.dv || this.apiData.dv === 0 ? ((this.apiData.dv) + "º") : "-")+" ")])]),_c('tr',[_c('th',{staticClass:"d-flex align-center justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t("aemet.soilTemperature")))]),(this.apiData.fint)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,false,4218361139)},[_c('span',[_vm._v(_vm._s(_vm.$t("aemet.soilTemperatureInfo")))])]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(this.apiData.ts || this.apiData.ts === 0 ? ((this.apiData.ts) + "ºC") : "-")+" ")])]),_c('tr',[_c('th',{staticClass:"d-flex align-center justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t("aemet.airTemperature")))]),(this.apiData.fint)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,false,4218361139)},[_c('span',[_vm._v(_vm._s(_vm.$t("aemet.airTemperratureInfo")))])]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(this.apiData.ta || this.apiData.ta === 0 ? ((this.apiData.ta) + "ºC") : "-")+" ")])]),_c('tr',[_c('th',{staticClass:"d-flex align-center justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t("aemet.relativeHumidity")))]),(this.apiData.fint)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,false,4218361139)},[_c('span',[_vm._v(_vm._s(_vm.$t("aemet.relativeHumidityInfo")))])]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(this.apiData.hr || this.apiData.hr === 0 ? ((this.apiData.hr) + "%") : "-")+" ")])])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }