

























































































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { aemetConfig } from "@/config/aemet";
import { mapMutations } from "vuex";
import * as types from "@/store/types";

@Component({
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    }
})
export default class CurrentWeather extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;

    currentIdema = [
        "7002Y - ÁGUILAS",
        "7012C - CARTAGENA",
        "7023X - FUENTE ALAMO",
        "7026X - TORRE PACHECO (C.C.A. AUT.)",
        "7031X - MURCIA/SAN JAVIER",
        "7066Y - EMBALSE DE LA FUENSANTA (AUTOMÁTICA)",
        "7072Y - SEGE",
        "7080X - BENIZAR",
        "7096B - HELLÍN",
        "7103Y - TOBARRA",
        "7119B - CARAVACA FUENTES DEL MARQUÉS",
        "7121A - CALASPARRA",
        "7127X - BULLAS",
        "7138B - JUMILLA EL ALBAL",
        "7145D - CIEZA PARQUE DE BOMBEROS",
        "7158X - ARCHENA",
        "7172X - MULA (P. BOMBEROS - AUT.)",
        "7178I - MURCIA",
        "7195X - CARAVACA (LOS ROYOS - AUT.)",
        "7203A - ZARZILLA DE RAMOS",
        "7209 - LORCA",
        "7211B - PUERTO LUMBRERAS",
        "7227X - ALHAMA (COMARZA - AUT.)",
        "7237E - MOLINA DE SEGURA (LOS VALIENTES)",
        "7244X - ORIHUELA DESAMPARADOS",
        "7247X - PINOSO",
        "7250C - ABANILLA",
        "7261X - ROJALES EL MOLINO",
        "7275C - YECLA"
    ];

    idemaCode = "";
    dataUrl = "";
    apiData = [];
    show = false;

    @Watch("idemaCode")
    async fetchUrl() {
        try {
            this.setProgressBar(true);
            const response = await fetch(
                `https://opendata.aemet.es/opendata/api/observacion/convencional/datos/estacion/${
                    this.idemaCode.split(" ")[0]
                }?api_key=${aemetConfig.accessToken}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "cache-control": "no-cache"
                    }
                }
            );
            if (response.ok) {
                const data = await response.json();
                this.dataUrl = data.datos;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(this.$t("aemet.error").toString());
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    @Watch("dataUrl")
    async fetchData() {
        try {
            const response = await fetch(`${this.dataUrl}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "cache-control": "no-cache"
                }
            });
            if (response.ok) {
                this.show = true;
                const data = await response.json();
                this.apiData = data[data.length - 1];
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(this.$t("aemet.error").toString());
            }
        }
    }

    /**
     * Show error message
     * @param {string} error Error message
     * @return {void}
     */
    showError(error: string): void {
        this.setInfoMessage({ shown: true, text: error });
    }
}
